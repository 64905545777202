import {
  abortNavigation,
  createError,
  defineNuxtRouteMiddleware,
} from "#imports";
import { storeToRefs } from "pinia";
import { useUserStore } from "~/stores/userStore";

export default defineNuxtRouteMiddleware(() => {
  const userStore = useUserStore();
  const { hasPermission } = storeToRefs(userStore);

  if (!hasPermission.value.incident.read)
    return abortNavigation(
      createError({
        statusCode: 401,
        message: "You do not have permission",
      })
    );
});
